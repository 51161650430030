<template>
  <div class="page-box">
    <base-screen>
      <a-form-model ref="formRef" layout="inline" :model="queryRef" @submit.native.prevent>
        <a-form-model-item label="活动名称" prop="activityName">
          <a-input v-model="queryRef.activityName" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item label="活动状态" prop="state">
          <a-select v-model="queryRef.state" placeholder="请选择" style="min-width: 120px">
            <a-select-option v-for="item in seckillActivityStatus" :key="item.value" :value="item.value">
              {{item.name}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" @click="handleSearch">查询</a-button>
          <a-button @click="handleReset">重置</a-button>
        </a-form-model-item>
      </a-form-model>
    </base-screen>
    <div class="btn-box">
      <a-button type="primary" @click="$router.push('/activity/seckill/add')">
        新建活动
      </a-button>
    </div>
    <base-table bordered :columns="columns" :dataSource="dataRef" :pagination="pageRtv" :rowKey="record => record.seckillActivityId" :loading="loadingRef || loading" @change="handleTableChange">
      <template #state="record">
        {{getStateText(record.activityState)}}
      </template>
      <template #operation="record">
        <div class="table-operations">
          <a-button type="link" @click="$router.push(`/activity/seckill/detail/${record.seckillActivityId}`)">
            查看
          </a-button>
          <a-popconfirm v-if="record.activityState !== 0" placement="topRight" title="当前操作不可撤销，确定将该活动关闭吗？" ok-text="确定" cancel-text="取消" @confirm="handleDisabledActivity(record.seckillActivityId, 0)">
            <a-button type="link">关闭</a-button>
          </a-popconfirm>

          <a-button v-if="record.activityState === 1" type="link" @click="$router.push(`/activity/seckill/edit/${record.seckillActivityId}`)">
            编辑
          </a-button>
          <a-popconfirm v-if="record.activityState === 0" placement="topRight" title="确定删除当前活动吗？" ok-text="确定" cancel-text="取消" @confirm="handleDisabledActivity(record.seckillActivityId, -1)">
            <a-button type="link">删除</a-button>
          </a-popconfirm>
        </div>
      </template>
    </base-table>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from '@vue/composition-api'
import { seckillActivityStatus } from '@/utils/constants'
import { useTableList } from '@/hooks'
import { activity } from '@/api'
import { SHOPID } from '@/constants'
import { getSession } from '@/utils/session'

const columns = [
  {
    title: '活动名称',
    dataIndex: 'activityName',
  },
  {
    title: '活动时间',
    dataIndex: 'activityTime',
    width: 200,
  },
  {
    title: '活动状态',
    scopedSlots: {
      customRender: 'state',
    },
  },
  {
    title: '订单实付金额(元)',
    dataIndex: 'realAmount',
  },
  {
    title: '付款订单数',
    dataIndex: 'payOrderNum',
  },
  {
    title: '付款人数',
    dataIndex: 'payPersonNum',
  },
  {
    title: '操作',
    scopedSlots: {
      customRender: 'operation',
    },
  },
]
export default {
  name: 'PageSeckill',

  setup (props, { root }) {
    const callback = () => {
      handleReset()
    }
    onMounted(() => {
      root.$bus.$on('PageSeckillList:refresh', callback)
    })
    onUnmounted(() => {
      root.$bus.$off('PageSeckillList:refresh', callback)
    })

    const formRef = ref(null)
    const queryRef = ref({
      activityName: '',
      state: undefined,
      shopId: getSession(SHOPID),
    })
    const { dataRef, pageRtv, loadingRef, resetPage, setPage, refresh } = useTableList(
      activity.getSeckillActivityPage,
      queryRef
    )

    function handleSearch () {
      resetPage()
      refresh()
    }

    function handleReset () {
      formRef.value.resetFields()
      handleSearch()
    }

    function handleTableChange ({ current }) {
      setPage(current)
    }

    function getStateText (state) {
      const matching = seckillActivityStatus.find((x) => x.value === +state)
      return matching ? matching.name : ''
    }

    const loading = ref(false)
    async function handleDisabledActivity (seckillActivityId, activityState) {
      loading.value = true
      const { code, msg } = await activity.disabledSeckillActivity({
        seckillActivityId,
        activityState,
      })
      loading.value = false
      if (code === '00000') {
        root.$message.success(activityState === 0 ? '关闭成功' : '删除成功')
        if (activityState === '-1' && dataRef.value.length === 1) {
          handleSearch()
        } else {
          refresh()
        }
      } else {
        root.$message.error(msg || '提交失败，请稍后重试')
      }
    }

    return {
      seckillActivityStatus,
      columns,
      formRef,
      queryRef,
      dataRef,
      pageRtv,
      loadingRef,
      handleSearch,
      handleReset,
      handleTableChange,
      getStateText,
      loading,
      handleDisabledActivity,
    }
  },
}
</script>

<style lang="less" scoped>
.btn-box {
  margin-bottom: 18px;
  ::v-deep .ant-btn {
    margin-right: 12px;
    &:last-child {
      margin: 0;
    }
  }
}
</style>
